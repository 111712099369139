import BasePage from '../../models/BasePage';
import Component from 'vue-class-component';
import { bookerService } from '@/main';
import Vue from 'vue';
import Booker from '../../models/Booker';
import { filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';
import { $router } from '@/router';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

@Component({
    mixins: [validationMixin],
    validations: {
        booker: {
            firstName: { required },
            lastName: { required },
            dateOfBirth: { required },
            emailAddress: { required },
            phoneNumber: { required },
            address: { required },
            city: { required },
            houseNumber: { required },
            postalCode: { required },
            streetName: { required },
        },
    },
})
export default class BookersPage extends BasePage {
    public bookers: Booker[] = [] as Booker[];
    public booker: Booker = new Booker();
    public slave: Booker = null;
    public master: Booker = null;
    public bookingsGridData: any = [];
    public showDeleted: boolean = false;
    public invalid: boolean = false;

    public bookersGridColumns = [
        { field: 'emailAddress', title: 'E-mail', filterCell: 'string-filter', cell: this.renderMaindbookerLink },
        { field: 'lastName', title: 'Achternaam', filterCell: 'string-filter' },
        { field: 'postalCode', title: 'Postcode', filterCell: 'string-filter' },
        { field: 'city', title: 'Plaats', filterCell: 'string-filter' },
        { field: 'phoneNumber', title: 'Telefoon', filterCell: 'string-filter' },
        { field: 'isBlacklisted', title: 'Zwartelijst', filter: 'boolean', cell: this.renderBlacklisted },
        { field: 'homeOwnerId', title: 'Huiseigenaar?', filterable: false, cell: this.renderHomeOwner },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: '150px' },
    ];

    public take: number = 25;
    public skip: number = 0;

    public filter: CompositeFilterDescriptor = {
        logic: 'and',
        filters: [],
    };

    public $refs!: {
        newBookerModal: BModal;
        mergeBookerModal: BModal;
    };

    public async mounted() {
        await this.initSite();
        await this.initBase();
        await this.loadBookers();
        this.isLoading = false;
    }

    public async loadBookers() {
        this.bookers = (await bookerService.getBookers(this.site.siteId, this.showDeleted)).data;
    }

    public editBooker(item: Booker) {
        const params = {
            site: this.siteKey,
            bookerId: item.mainBookerId.toString(),
        };

        $router.push({ name: 'booker', params });
    }

    public async reloadBookers() {
        this.showDeleted = !this.showDeleted;
        this.showPending('Hoofdboekers ophalen..');
        await this.loadBookers();

        this.clearNotifications();
    }

    public get total() {
        return this.bookers ? this.bookers.length : 0;
    }

    public filteredBookers(): Booker[] {
        const bookers = filterBy(this.bookers, this.filter);
        return bookers.slice(this.skip, this.take + this.skip);
    }

    public filterChange(ev) {
        this.skip = 0;
        this.filter = ev.filter;
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public addNewBooker() {
        this.booker = new Booker();
        this.$refs.newBookerModal.show();
    }

    public cancel() {
        this.$refs.newBookerModal.hide();
        this.invalid = false;
    }

    public async showConfirmArchive(item) {
        const answer = await this.$bvModal.msgBoxConfirm('Weet je zeker dat je hoofdboeker wilt archiveren?', {
            title: 'Hoofdboeker archiveren',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: 'Ja',
            cancelTitle: 'Nee',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
        });

        if (answer) {
            await bookerService.archiveBooker(item.mainBookerId, this.siteId);
            if (!this.showDeleted) {
                this.bookers = this.bookers.filter((x) => x.mainBookerId !== item.mainBookerId);
            }
        }
    }

    public showMergeBookersModal() {
        this.booker = new Booker();
        this.$refs.mergeBookerModal.show();
    }

    public async mergeBookers() {
        this.showPending('Merging hoofdboekers...');
        const [err, response] = await to(bookerService.mergeBookers(this.slave.mainBookerId, this.master.mainBookerId, this.site.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om de hoofdboekers mergen', err);
            return;
        }

        this.clearAndShowSuccess('Hoofdboekers succesvol gemerged.');
        this.$refs.mergeBookerModal.hide();
        this.master = null;
        this.slave = null;

        await this.loadBookers();
    }

    public cancelMerge() {
        this.$refs.mergeBookerModal.hide();
    }

    public async saveNewBooker() {
        if (this.$v.booker.$invalid) {
            this.invalid = true;
            return this.clearAndShowWarning('Niet alle verplichte velden zijn ingevuld');
        }
        this.invalid = false;
        const [err, response] = await to(bookerService.createNewBooker(this.booker, this.site.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om een hoofdboeker aan te maken.', err);
            return;
        }

        this.clearAndShowSuccess('Hoofdboeker succesvol aangemaakt.');
        this.$refs.newBookerModal.hide();
        await this.loadBookers();
    }

    private renderMaindbookerLink(h: any, _, row) {
        const mainBooker = row.dataItem;
        const route = $router.resolve({
            name: 'booker',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookerId: row.dataItem.mainBookerId },
        });

        const linkText = mainBooker.emailAddress ? mainBooker.emailAddress : '(leeg)';
        const props = { text: linkText, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    private renderActions(h: any, a, row) {
        const actions = [
            { title: 'Bewerk', function: this.editBooker },
            { title: 'Archiveer', function: this.showConfirmArchive },
        ];
        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }

    private renderHomeOwner(h: any, _, row) {
        return h('td', [row.dataItem.homeOwnerId && row.dataItem.homeOwnerId > 0 ? 'Ja' : 'Nee']);
    }

    private renderBlacklisted(h: any, _, row) {
        return h('td', [row.dataItem.isBlacklisted ? 'Ja' : 'Nee']);
    }

    private getBookerName(booker) {
        const mainbookerId = booker.mainBookerId ? booker.mainBookerId : booker;
        const fBooker = this.bookers.find((x) => x.mainBookerId === mainbookerId);

        return `${new Booker(fBooker).name()} (${fBooker.emailAddress})`;
    }
}
